import { View, TouchableOpacity, Text, ScrollView } from 'react-native';
import AdminLayout from '../../components/layout/AdminLayout';
import { Svg, Path } from 'react-native-svg';
import { AddProductManuallyScreenProps } from '../../navigation/appNavigation';
import { appColors } from '../../global/constant/colors';
import { FC, useEffect, useState } from 'react';
import { CustomInput, CustomButton, CustomImageUpload, CustomDialog } from '../../components';
import useProduct from '../../hooks/useProduct';
import { RadioButton } from 'react-native-paper';

const AddProductManuallyScreen: FC<AddProductManuallyScreenProps> = ({ navigation, route }) => {
  const { createProductItemManually } = useProduct();
  const { productNameGroupParamsData }: any = route.params;
  const [productInfo, setProductInfo] = useState({
    name: productNameGroupParamsData.product_name_group,
    description: '',
    stock: '',
    stock_value: '',
    price: productNameGroupParamsData.price,
    cost_per_unit: '',
  });

  const onClearFun = () => {
    setProductInfo({
      name: productNameGroupParamsData.product_name_group,
      description: '',
      stock: '',
      stock_value: '',
      price: productNameGroupParamsData.price,
      cost_per_unit: '',
    });
  };

  const onSubmit = () => {
    createProductItemManually(
      {
        name: productNameGroupParamsData.product_name_group,
        product_name_group_id: productNameGroupParamsData?.id,
        category_id: productNameGroupParamsData.product_category_id,
        description: productInfo.description,
        is_single: 0,
        stock: productInfo.stock,
        stock_value: productInfo.stock_value,
        cost_per_unit: productInfo.cost_per_unit,
      },
      onClearFun,
    );
  };

  console.log('==>', productNameGroupParamsData.product_name_group);
  const [value, setValue] = useState('');
  return (
    <>
      <AdminLayout
        content={
          <ScrollView style={{ height: '100%' }} showsVerticalScrollIndicator={false}>
            <View>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <View style={{ flexDirection: 'row' }}>
                  <Svg style={{ marginTop: -2 }} width="25" height="25" viewBox="0 0 40 40" fill="none">
                    <Path
                      d="M29.725 6.45L26.7584 3.5L10.275 20L26.775 36.5L29.725 33.55L16.175 20L29.725 6.45Z"
                      fill="#1E1E1E"
                    />
                  </Svg>
                  <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>ADD PRODUCT</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: "center", marginTop: 50 }}>
              {/* <CustomImageUpload /> */}
              <View style={{ width: '45%', height: '100%', marginBottom: 50, marginTop: 10,  backgroundColor: "#ffffff", padding: 30, borderRadius: 20, }}>
                <CustomInput
                  value={productInfo.name}
                  placeholder="Product Name"
                  onChangeText={(e) => setProductInfo({ ...productInfo, name: e })}
                  inputMode="text"
                  editable={false}
                />
                <CustomInput
                  value={productInfo.price}
                  placeholder="Product Price"
                  onChangeText={(e) => setProductInfo({ ...productInfo, price: e })}
                  inputMode="text"
                  editable={false}
                />
                <CustomInput
                  value={productInfo.cost_per_unit}
                  placeholder="Product Cost Price Per Unit"
                  onChangeText={(e) => setProductInfo({ ...productInfo, cost_per_unit: e })}
                  inputMode="text"
                />
                <CustomInput
                  value={productInfo.description}
                  placeholder="Product Description"
                  onChangeText={(e) => setProductInfo({ ...productInfo, description: e })}
                  inputMode="text"
                />
                <View style={{ marginTop: 20, marginBottom: -15 }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 20 }}>Select Stock Type</Text>
                  <RadioButton.Group
                    onValueChange={(newValue) => setProductInfo({ ...productInfo, stock_value: newValue })}
                    value={productInfo.stock_value}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ marginRight: 30 }}>
                        <Text>(recommended for unit products)</Text>
                        <RadioButton.Item labelStyle={{ fontWeight: 'bold', fontSize: 20 }} label="Pieces" value="0" />
                      </View>
                      <View style={{ marginLeft: 30 }}>
                        <Text>(recommended for scaled products)</Text>
                        <RadioButton.Item
                          labelStyle={{ fontWeight: 'bold', fontSize: 20 }}
                          label="Kilogram"
                          value="1"
                        />
                      </View>
                    </View>
                  </RadioButton.Group>
                </View>

                {productInfo.stock_value !== '' && (
                  <CustomInput
                    value={productInfo.stock}
                    placeholder="Product Stock"
                    onChangeText={(e) => setProductInfo({ ...productInfo, stock: e })}
                    inputMode="numeric"
                  />
                )}
                <View style={{ marginTop: 30 }}>
                  <CustomButton
                    fontSize={14}
                    padding={5}
                    width={'100%'}
                    bntType="primary"
                    mode="contained"
                    text="SAVE"
                    onPress={onSubmit}
                  />
                </View>
              </View>
            </View>
            <CustomDialog />
          </ScrollView>
        }
      />
    </>
  );
};

export default AddProductManuallyScreen;
