import { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, TextInput, ScrollView, FlatList } from 'react-native';
import { appColors } from '../global/constant/colors';
import CustomTillProductCard from './CustomTillProductCard';
import { Svg, Path } from 'react-native-svg';
import useCategory from '../hooks/useCategory';
import useProduct from '../hooks/useProduct';
import useOrg from '../hooks/useOrg';
import CustomButton from './CustomButton';

const ProductContainer = () => {
  // const [showProduct, setShowProduct] = useState('category');
  const { getAllCategoryOnLocalDB, searchCategoryWithKeyWordOnLocalDB, showProduct, setShowProduct } = useCategory();
  const {
    searchProductGroupNameWithKeyWordOnLocalDB,
    getAllProductOnLocalDB,
    getAllProductOnLocalDBByKeySearch,
    productNameItemData,
    allShopProductData,
  } = useProduct();
  const { getOrgData, org } = useOrg();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getAllCategoryOnLocalDB();
    getAllProductOnLocalDB();
    getOrgData();
  }, []);

  useEffect(() => {
    getAllProductOnLocalDBByKeySearch(searchQuery);
    getOrgData();
  }, [searchQuery]);

  const onLoad = () => {
    getAllProductOnLocalDB;
  };

  return (
    <>
      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontWeight: '700', color: 'rgba(30, 30, 30, 1)', marginLeft: 10 }}>PRODUCTS</Text>
        </View>

        <div style={{ marginTop: -20, width: '100%', marginLeft: 20, justifyContent: 'flex-end', display: 'flex' }}>
          <TextInput
            textAlign="center"
            textAlignVertical="center"
            keyboardAppearance="default"
            placeholder="Search"
            style={{
              height: 50,
              padding: 13,
              backgroundColor: appColors.secondary,
              borderColor: 'rgba(30, 30, 30, 0.5)',
              borderWidth: 1,
              borderRadius: 10,
            }}
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
        </div>
      </View>

      <FlatList
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          marginTop: 40,
        }}
        ListHeaderComponent={
          <>
            {allShopProductData.length < 1 && (
              <>
                <Text style={{ fontSize: 15, fontWeight: '900', textAlign: 'center' }}>No Product Created Yet</Text>
                <div style={{ marginTop: '100px' }}>
                  <CustomButton
                    padding={10}
                    width={'100%'}
                    bntType="primary"
                    mode="outlined"
                    text={'Load Data'}
                    onPress={() => onLoad()}
                  />
                </div>
              </>
            )}
          </>
        }
        numColumns={3}
        keyExtractor={(item,index: any) => index}
        renderItem={({ item }) => <CustomTillProductCard data={item} />}
        data={allShopProductData}
      />
    </>
  );
};

export default ProductContainer;
